<template>
  <div
    v-if="hasPermission('access_dashboard')"
    class="col"
    v-loading="
      $store.getters['dashboard/load'] ||
        $store.getters['dashboard/taskload'] ||
        $store.getters['dashboard/orgsload']
    "
  >
    <div class="row">
      <div class="col-xl-4 col-md-6">
        <div class="overview_our_custom_card">
          <div class="icons">
            <i class="fa fa-users icon"></i>
          </div>
          <div class="titles">
            <span class="d-block main">{{ localization("Individuals") }}</span>
            <span class="d-block subtitle">{{ indivedualCount }} </span>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6">
        <div class="overview_our_custom_card">
          <div class="icons">
            <i class="fa fa-graduation-cap icon"></i>
          </div>
          <div class="titles">
            <span class="d-block main">{{ localization("Students") }}</span>
            <span class="d-block subtitle">{{ userCount }} </span>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6">
        <div class="overview_our_custom_card">
          <div class="icons">
            <i class="nc-icon nc-chart icon"></i>
          </div>
          <div class="titles">
            <span class="d-block main">{{
              localization("Institutional Users")
            }}</span>
            <span class="d-block subtitle">{{ institutionCount }} </span>
          </div>
        </div>
      </div>
      <!-- Start New Card For Users Count-->

      <!-- //// new div for remove -->

      <div class="col-xl-4 col-md-6">
        <div class="overview_our_custom_card">
          <div class="icons">
            <i class="fa fa-users icon"></i>
          </div>
          <div class="titles">
            <span class="d-block main">Users</span>
            <span class="d-block subtitle"
              >{{ UsersCount.active_users }} /
              {{ UsersCount.all_users }}
            </span>
          </div>
        </div>
      </div>
      <!-- End New For Users Count -->

      <!-- Start Users Status -->
      <div class="col-xl-4 col-md-6">
        <div class="overview_our_custom_card">
          <div class="icons">
            <i class="fa fa-users icon"></i>
          </div>
          <div class="titles">
            <span class="d-block main">users did not finish LCI</span>
            <span class="d-block subtitle"
              >{{ UsersStatistics.users_didnot_finish_lci_count }} /
              {{ UsersCount.all_users }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6">
        <div class="overview_our_custom_card">
          <div class="icons">
            <i class="fa fa-users icon"></i>
          </div>
          <div class="titles">
            <span class="d-block main">users did not finish PLP</span>
            <span class="d-block subtitle"
              >{{ UsersStatistics.users_didnot_finish_plp_count }} /
              {{ UsersCount.all_users }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6">
        <div class="overview_our_custom_card">
          <div class="icons">
            <i class="fa fa-users icon"></i>
          </div>
          <div class="titles">
            <span class="d-block main">users did not decode tasks</span>
            <span class="d-block subtitle"
              >{{ UsersStatistics.users_didnot_decode_tasks_count }} /
              {{ UsersCount.all_users }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6">
        <div class="overview_our_custom_card">
          <div class="icons">
            <i class="fa fa-users icon"></i>
          </div>
          <div class="titles">
            <span class="d-block main">users did not join teams</span>
            <span class="d-block subtitle"
              >{{ UsersStatistics.users_not_in_teams_count }} /
              {{ UsersCount.all_users }}</span
            >
          </div>
        </div>
      </div>

      <!-- End Users Status -->
    </div>
    <div class="row ">
      <div class="col-md-6 " v-if="!$store.getters['dashboard/orgsload']">
        <chart-card
          :chart-data="pieChart"
          :chart-options="barChart.options"
          chart-type="Pie"
        >
          <template slot="header">
            <div class="d-flex Main_header_with " :class="{ is_rtl: isRtl() }">
              <i
                class="el-icon el-icon-pie-chart"
                style="font-size: 30px; margin-right: 7px"
              ></i>
              <h4 class="card-title">{{ localization("Institutions") }}</h4>
            </div>
          </template>
          <template slot="footer"> </template>
        </chart-card>
      </div>
      <div class="col-md-6" v-if="!$store.getters['dashboard/taskload']">
        <chart-card
          :chart-data="TaskChart"
          :chart-options="barChart.options"
          :chart-responsive-options="barChart.responsiveOptions"
        >
          <template slot="header">
            <div class="d-flex Main_header_with" :class="{ is_rtl: isRtl() }">
              <i
                class="el-icon el-icon-data-analysis"
                style="font-size: 30px; margin-right: 7px"
              ></i>
              <h4 class="card-title">{{ localization("Tasks") }}</h4>
            </div>
          </template>
        </chart-card>
      </div>
      <div class="col-md-12" v-if="!$store.getters['dashboard/load']">
        <chart-card
          :chart-data="ActivityChart"
          :chart-options="lineChart.options"
          :responsive-options="lineChart.responsiveOptions"
        >
          <template slot="header">
            <div class="d-flex" :class="{ 'flex-row-reverse': isRtl() }">
              <i
                class="el-icon el-icon-data-line"
                style="font-size: 30px; margin-right: 7px"
              ></i>
              <h4 class="card-title">{{ localization("Activity") }}</h4>
            </div>
          </template>
        </chart-card>
      </div>
    </div>
  </div>
  <div class="col" v-else>
    <show></show>
  </div>
</template>

<script>
import { ChartCard, StatsCard } from "src/components/index";
import show from "../../../views/users/show";

export default {
  components: {
    ChartCard,
    StatsCard,
    show
  },
  data() {
    return {
      logscount: [],
      indivedualCount: null,
      institutionCount: null,
      userCount: null,
      logsdate: [],
      lineChart: {
        options: {
          low: 0,
          showArea: true,
          height: "350px",
          axisX: {
            showGrid: true
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true
          // chartPadding: {
          //   right: 10
          // }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      barChart: {
        options: {
          seriesBarDistance: 20,
          showPoint: true,
          donut: true,
          labelDirection: "explode",
          chartPadding: 0,
          labelOffset: 20,
          labelInterpolationFnc: function(value) {
            return `${value.split(" ")[0]}...`;
          },
          height: "245px",
          width: "95%"
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      }
    };
  },

  computed: {
    pieChart() {
      let data = {};
      data.labels = this.$store.getters["dashboard/orgsname"];
      data.series = this.$store.getters["dashboard/orgscount"];
      return data;
    },
    ActivityChart() {
      let data = {};
      data.labels = this.$store.getters["dashboard/logsdate"];
      data.series = [this.$store.getters["dashboard/logscount"]];
      return data;
    },
    TaskChart() {
      let data = {};
      data.labels = this.$store.getters["dashboard/taskdate"];
      data.series = [this.$store.getters["dashboard/taskcount"]];
      return data;
    },
    UsersCount() {
      return this.$store.getters["dashboard/get_activation_count"];
    },
    UsersStatistics() {
      return this.$store.getters["dashboard/get_users_statistics"];
    }
  },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      this.$store.dispatch("dashboard/users_type").then(_ => {
        this.institutionCount = this.$store.getters[
          "dashboard/institutioncount"
        ];
        this.indivedualCount = this.$store.getters["dashboard/individualcount"];
        this.userCount = this.$store.getters["dashboard/Studentcount"];
      });
      this.$store.dispatch("dashboard/logs_count");
      this.$store.dispatch("dashboard/org_count");
      this.$store.dispatch("dashboard/task_count").then(_ => {});
      this.$store.dispatch("dashboard/handlerActivationCount");
      this.$store.dispatch("dashboard/handlerUsersStatistics");
    }
  }
};
</script>

<style lang="scss" scoped>
.Main_header_with {
  &.is_rtl {
    flex-direction: row-reverse;
  }
}
.overview_our_custom_card {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border: 1px solid #00000020;
  border-radius: 5px;
  padding: 15px;
  min-height: 160px;
  align-items: center;
  margin-bottom: 25px;
  .icons {
    .icon {
      font-size: 45px;
      color: #23ccef;
    }
  }
  .titles {
    text-align: center;
    .main {
      font-size: 20px;
      font-weight: 400;
      color: #9a9a9a;
      text-transform: capitalize;
      margin-bottom: 10px;
    }
    .subtitle {
      color: #333333;
      font-weight: 300;
      font-size: 17px;
    }
  }
}
</style>
