<template>
  <div>
    <div>
      <img src="@/assets/new-theme/PLC-Logo.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoContainer"
};
</script>

<style lang="scss" scoped></style>
