// import {GetQuestions, SaveQuestions,deleteQuestion} from '../../api/questionnaire'; // getInfo
import Cms from "../../api/cms"; // getInfo
// import {Auth, deleteToken, removeAuth, removeToken, resetPermission, setLogged, setToken} from '../../utils/auth';
const FileDownload = require("js-file-download");
const cms = new Cms();

const cmsModule = {
  namespaced: true,
  state: {
    usersLoad: false,
    cms: [],
    languages: [],
    totalcms: null,
    localization:[],
    localizationCount:null,
    localizationLanguages:[],
    showLocalization:{},
    showLocalizationRegister:{},
  },
  getters: {
    cms: state => {
      return state.cms;
    },
    languages: state => {
      return state.languages;
    },
    totalcms: state => {
      return state.totalcms;
    },
    usersLoad: state => {
      return state.usersLoad;
    },
    getLocalization: state => {
      return state.localization;
    },
    getLocalizationCount: state => {
      return state.localizationCount;
    },
    getLocalizationLanguages: state => {
      return state.localizationLanguages;
    },
    getShowLocalization: state => {
      console.log(state.showLocalization);
      if (state.showLocalization && state.showLocalization.localization) {
        return  Object.entries(
          state.showLocalization.localization
        ).map(([key, value ]) => {
          return { key, value };
        })
      }
    },
    getShowLocalizationRegisterPage: state => {
      if (Array.isArray(state.showLocalization) == false) {
        return state.showLocalization.localization

      } else {
        // console.log("nottttttt");
        // console.log(state.showLocalization[0]);
        return state.showLocalization[0]
      }
    },


  },

  mutations: {
    SET_CMS: (state, cms) => {
      state.cms = cms;
    },
    SET_LANGUAGES: (state, languages) => {
      state.languages = languages;
    },
    SET_TOTAL_CMS: (state, total) => {
      state.totalcms = total;
    },
    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    },
    SET_LOCALIZATION: (state, local) => {
      state.localization = local;
    },
    SET_LOCALIZATION_COUNT: (state, local) => {
      state.localizationCount = local;
    },
    SET_LOCALIZATION_LANGUAGES: (state, local) => {
      state.localizationLanguages = local;
    },
    SET_SHOW_LOCALIZATION: (state, one) => {
      state.showLocalization = one;
    },
    SET_SHOW_LOCALIZATION_REGISTRATION: (state, one) => {
      state.showLocalizationRegister = one;
    },
  },
  actions: {
    GetCMS({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        cms
          .list(payload.query)
          .then(response => {
            commit("SET_CMS", response.cms);
            commit("SET_TOTAL_CMS", response.count);
            commit("SET_LANGUAGES", response.languages);
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    cms({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        cms
          .get(payload.id)
          .then(response => {
            commit("SET_CMS", response.cms);
            commit("SET_LANGUAGES", response.languages);
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    add({ commit }, payload) {
      return new Promise((resolve, reject) => {
        cms
          .add(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    UpdateCMS({ commit }, payload) {
      return new Promise((resolve, reject) => {
        cms
          .update(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    removeCms({ commit }, payload) {
      return new Promise((resolve, reject) => {
        cms
          .delete(payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
      //// Localization
    getLocalization({ commit },payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        cms
          .localizationList(payload.query)
          .then(response => {

            commit("SET_LOCALIZATION",response.localization);
            commit("SET_LOCALIZATION_COUNT", response.count);

            commit("SET_USERS_LOAD", false);
            resolve(response)
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },

    getEveryLocalization({ commit },payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        cms
          .showLocalization(payload.id)
          .then(response => {
            commit("SET_SHOW_LOCALIZATION",response.localization);
            commit("SET_USERS_LOAD", false);
            // console.log(response.localization);
            resolve(response)
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },

    EditLocalization({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        cms
          .editLocalization(payload.query, payload.id)
          .then(response => {
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },

    // Localization Register Page
    getEveryLocalizationForRegisterPages({ commit },payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        cms
          .getLocalizationForRegistration(payload)
          .then(response => {
            commit("SET_SHOW_LOCALIZATION",response.localization);
            commit("SET_SHOW_LOCALIZATION_REGISTRATION",response.localization[0]);
            commit("SET_USERS_LOAD", false);
            // console.log(response.localization[0]);
            resolve(response)
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },


    getLocalizationForRegister({ commit },payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        cms
          .showLocalization(payload.id)
          .then(response => {
            commit("SET_SHOW_LOCALIZATION_REGISTRATION",response.localization);
            commit("SET_USERS_LOAD", false);
            // console.log(response.localization);
            resolve(response)
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
    // Export Localization
    exportMainLocalization({ commit },payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        cms
          .exportLocalization(payload.id)
          .then(response => {
            FileDownload(response, `${payload.localization_language_name}.xlsx`);
            commit("SET_USERS_LOAD", false);
            resolve(response)
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
  }
};

export default cmsModule;
