import Strategies from "../../api/strategies"; // getInfo
const FileDownload = require("js-file-download");
const strategies = new Strategies();

const strategiesModule = {
  namespaced: true,
  state: {
    usersLoad: false,
    topics: [],
    strategies: [],
    languages: [],
    total: null,
    strategy: null,
    atWorkTopics:[],
    careerTopics:[],
  },
  getters: {
    strategies: state => {
      return state.strategies;
    },
    strategy: state => {
      return state.strategy;
    },
    languages: state => {
      return state.languages;
    },
    total: state => {
      return state.total;
    },
    topics: state => {
      return state.topics;
    },
    usersLoad: state => {
      return state.usersLoad;
    },
    getAtWorkTopics: state => {
      return state.atWorkTopics;
    },
    getCareerTopics: state => {
      return state.careerTopics;
    },
  },

  mutations: {
    SET_STRATEGIES: (state, strategies) => {
      state.strategies = strategies;
    },
    SET_STRATEGY: (state, strategy) => {
      state.strategy = strategy;
    },
    SET_TOTAL_STRATEGY: (state, total) => {
      state.total = total;
    },
    SET_TOPICS: (state, topics) => {
      state.topics = topics;
    },
    SET_LANG: (state, languages) => {
      state.languages = languages;
    },
    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    },


    SET_AT_WORK_TOPICS: (state, load) => {
      state.atWorkTopics = load;
    },
    SET_CAREER_TOPICS: (state, load) => {
      state.careerTopics = load;
    },
  },
  actions: {
    Getstrategies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        strategies
          .strategies(payload.query)
          .then(response => {
            commit("SET_STRATEGIES", response.starategies);
            commit("SET_LANG", response.languages);
            commit("SET_TOTAL_STRATEGY", response.count);
            commit("SET_TOPICS", response.topics);
            commit("SET_AT_WORK_TOPICS", response.work_topics);
            commit("SET_CAREER_TOPICS", response.career_topics);

            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    strategy({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        strategies
          .show_strategy(payload.id)
          .then(response => {
            commit("SET_STRATEGY", response.strategy);
            commit("SET_LANG", response.languages);
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    Import({ commit }, payload) {
      return new Promise((resolve, reject) => {
        strategies
          .Import(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    Export({ commit }, payload) {
      return new Promise((resolve, reject) => {
        strategies.Export(payload.query)
          .then(response => {
            FileDownload(response, "strategies.xlsx");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // Bulk process
    ImportBulk({ commit }, payload) {
      return new Promise((resolve, reject) => {
        strategies
          .Import_bulk(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    ExportBulk({ commit }, payload) {
      return new Promise((resolve, reject) => {
        strategies
          .Export_bulk()
          .then(response => {
            FileDownload(response, "Temp_strategies.xlsx");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    addCustom({ commit }, payload) {
      return new Promise((resolve, reject) => {
        strategies
          .addCustom(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    UpdateStrategies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        strategies
          .update(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    AddStrategies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        strategies
          .add(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        strategies
          .delete(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // at work and career
    handlerAddCustomAtWorkStrategy({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        strategies
          .addCustomAtWorkStrategy(payload.query, payload.id)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    handlerAddCustomCareerStrategy({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        strategies
          .addCustomCareerStrategy(payload.query, payload.id)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
  },
};

export default strategiesModule;
