import Teams from '../../api/teams'; // getInfo
import Step1 from '../../views/a-new-theme/teams/step-1.vue';
const FileDownload = require('js-file-download');
const teams = new Teams();

const teamsModule = {
  namespaced: true,
  state: {
    usersLoad: false,
    teams: [],
    languages: [],
    topic: {},
    totalteams: null,
    totalCategories: null,
    questionOne: null,
    questionTwo: null,
  },
  getters: {
    teams: (state) => {
      return state.teams
    },
    languages: (state) => {
      return state.languages
    },
    topic: (state) => {
      return state.topic
    },
    totalteams: (state) => {
      return state.totalteams
    },
    getTotalCategories: (state) => {
      return state.totalCategories
    },
    usersLoad: (state) => {
      return state.usersLoad
    },
    questionOne: (state) => {
      return state.questionOne;
    },
    questionTwo: (state) => {
      return state.questionTwo;
    },
  },

  mutations: {
    SET_teams: (state, teams) => {
      state.teams = teams;
    },
    SET_TOPIC: (state, topic) => {
      state.topic = topic;
    },
    SET_LANG: (state, languages) => {
      state.languages = languages;
    },
    SET_TOTAL_teams: (state, totalteams) => {
      state.totalteams = totalteams;
    },
    SET_TOTAL_CATEGORIES: (state, totalCategories) => {
      state.totalCategories = totalCategories;
    },
    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    },
    SET_QUESTION_ONE: (state, load) => {
      state.questionOne = load;
    },
    SET_QUESTION_TWO: (state, load) => {
      state.questionTwo = load;
    },
  },
  actions: {
    step1({ commit }) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        teams
          .question_one_statements()
          .then(response => {
            commit("SET_QUESTION_ONE", response );
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
    step2({ commit }) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        teams
          .question_two_statements()
          .then(response => {
            commit("SET_QUESTION_TWO", response );
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
    join_team({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        teams
          .join_team(payload.query)
          .then(response => {
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
  }
};

export default teamsModule;
