<template>
  <div>
    <div class="lci_card">
      <img :src="item.src" alt="" />
      <h3 class="mt-3">
        {{ item.title }}
      </h3>
      <p class="m-0">
        {{ item.description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LciCard",
  props: {
    item: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
img{
  max-width: 45px;
}
.lci_card {
  border: 1px solid #eaecf0;
  border-radius: 16px;
  padding: 10px 20px;
  min-height: 200px;
  background: #fff;
  margin-bottom: 10px;
  h3 {
    color: #101828;
    font-size: 18px;
    font-weight: 600;
  }
  padding: {
    color: #667085;
    font-size: 16px;
    font-weight: 400;
  }
}
</style>
