<template>
  <div class="container-login100">
    <div
      class="header-container d-flex justify-content-between align-items-center"
    >
      <!-- Back Arrow and Title -->
      <div class="d-flex align-items-center for-backing"></div>

      <!-- Language Selector Dropdown -->
      <el-dropdown class="language-selector">
        <el-button>
          <img
            src="https://hatscripts.github.io/circle-flags/flags/us.svg"
            alt="US Flag"
            width="20"
            height="20"
            class="mr-2"
          />
          English <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="en">
            <img
              src="https://hatscripts.github.io/circle-flags/flags/us.svg"
              alt="US Flag"
              width="20"
              height="20"
              class="mr-2"
            />
            English
          </el-dropdown-item>
          <el-dropdown-item command="fr">
            <img
              src="https://hatscripts.github.io/circle-flags/flags/fr.svg"
              alt="French Flag"
              width="20"
              height="20"
              class="mr-2"
            />
            French
          </el-dropdown-item>
          <el-dropdown-item command="es">
            <img
              src="https://hatscripts.github.io/circle-flags/flags/es.svg"
              alt="Spanish Flag"
              width="20"
              height="20"
              class="mr-2"
            />
            Spanish
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-row style="width: 100%">
      <!-- Login Form src="../../../assets/images/logo/03.png"  -->
      <el-col :span="12" class="bg-white rounded-start">
        <fade-render-transition
          :duration="500"
          class="pt-3 pb-5"
          style="width: 60%; margin:10rem auto 0"
        >
          <el-form
            v-model="resetPassword"
            :model="resetPassword"
            ref="resetPassRef"
            :rules="resetPassRules"
            class="p-4 w-90"
            @submit.prevent="submit"
          >
            <!-- Icon at the top -->
            <div class="reset-confirmation text-center">
              <img
                src="../../../assets/images/icons/pass_key.png"
                alt="Email Icon"
                class="email-icon"
              />

              <!-- Header Text -->
              <h2 class="mt-4">Set new password</h2>
              <p class="text-secondary">
                Your new password must be different from previously used
                passwords.
              </p>
            </div>

            <div>
              <el-form-item
                label="New Password"
                prop="password"
                :class="{ is_arabic: ar_lang == true }"
                class="text-capitalize"
              >
                <!-- Show Password Icon  -->
                <i
                  v-if="passwordType == 'password'"
                  class="
                      fa fa-eye
                      position-absolute
                      togglePassword
                      text-secondary
                    "
                  @click="toggleShowPassword"
                  v-tooltip.top-center="'Show Password'"
                  aria-hidden="true"
                ></i>
                <i
                  v-if="passwordType == 'text'"
                  class="
                      fa fa-eye-slash
                      position-absolute
                      togglePassword
                      text-secondary
                    "
                  @click="toggleShowPassword"
                  v-tooltip.top-center="'Hide Password'"
                  aria-hidden="true"
                ></i>
                <el-input
                  :type="passwordType"
                  v-model="resetPassword.password"
                  placeholder="Please enter Password"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="Re-enter new Password"
                prop="confirmPassword"
                class="position-relative "
              >
                <i
                  v-if="confirmPasswordType == 'password'"
                  class="
                      fa fa-eye
                      position-absolute
                      togglePassword
                      text-secondary
                    "
                  @click="toggleShowConfirmPassword"
                  v-tooltip.top-center="'Show Password'"
                  aria-hidden="true"
                ></i>
                <i
                  v-if="confirmPasswordType == 'text'"
                  class="
                      fa fa-eye-slash
                      position-absolute
                      togglePassword
                      text-secondary
                    "
                  @click="toggleShowConfirmPassword"
                  v-tooltip.top-center="'Hide Password'"
                  aria-hidden="true"
                ></i>
                <el-input
                  :type="confirmPasswordType"
                  v-model="resetPassword.confirmPassword"
                  placeholder="Please enter Password"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </div>

            <div style="width: 100%" class="mb-3 mt-3 text-center">
              <el-button
                @click="submit"
                type="primary"
                class="email-app-button"
              >
                Reset password
              </el-button>
              <el-link
                :underline="false"
                class="mt-3 back-to-login"
                @click="navigateToLogin"
              >
                <i class="el-icon-back"></i>
                Back to log in
              </el-link>
            </div>
          </el-form>
        </fade-render-transition>
      </el-col>

      <el-col :span="12" class="bg-light d-none d-md-block rounded-end">
        <div class="">
          <img
            src="../../../assets/images/frames/Frame 90.png"
            alt="Dashboard preview"
            class="img-fluid rounded w-100"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { FadeRenderTransition } from "src/components/index";
import Swal from "sweetalert2";
import Button from "../../../components/Button.vue";

export default {
  name: "ResetPassword",
  data() {
    return {
      passwordType: "password",
      confirmPasswordType: "password",
      resetPassword: {},
      resetPassRules: {
        password: [
          {
            required: true,
            message: "Please input password",
            trigger: "blur"
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please reenter password",
            trigger: "blur"
          }
        ]
      }
    };
  },
  components: {
    FadeRenderTransition,
    Button
  },
  mounted() {
    // window.addEventListener("keypress", (e) => {
    //   if (e.key == "Enter") {
    //     this.submit();
    //   }
    // });
  },
  methods: {
    navigateToLogin() {
      this.$router.push("/login"); // If using Vue Router
      // Or for a full-page reload:
      // window.location.href = 'http://localhost:8080/login';
    },
    toggleShowPassword() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    toggleShowConfirmPassword() {
      this.confirmPasswordType =
        this.confirmPasswordType == "password" ? "text" : "password";
    },
    submit() {
      this.$refs["resetPassRef"].validate(valid => {
        if (valid) {
          //  If passwords does not match
          if (
            this.resetPassword.password !== this.resetPassword.confirmPassword
          ) {
            Swal.fire({
              icon: "error",
              text: "Passwords does not match",
              confirmButtonClass: "btn btn-info btn-fill",
              showConfirmButton: false,
              showCloseButton: true
            });
          } else {
            // call reset password on store
            this.$store.dispatch("user/resetNewPassword", {
              newPasswordData: {
                password: this.resetPassword.password,
                confirm_password: this.resetPassword.confirmPassword,
                token: this.$route.params.token
              }
            });
            // only redirect to login if enter 2 matching passwords
            this.$router.push("/login");
          }
        } else {
          console.log("error submitting");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.header-container {
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0; /* Light gray border for separation */
  background-color: #ffffff; /* White background */
}

.header-container .fw-bold {
  font-size: 22px;
  color: #333333;
  font-weight: 500;
}

.language-selector {
  display: flex;
  align-items: center;
}

.language-selector .el-button {
  border-radius: 8px;
  padding: 9px 10px;
  font-size: 14px;
  color: #333333; /* Text color */
  background-color: #f9f9f9; /* Light background color */
  display: flex;
  align-items: center;
  gap: 8px; /* Space between flag icon and text */
}

.language-selector img {
  border-radius: 50%; /* Circular flag icons */
  margin-right: 1px;
  margin-bottom: 3px;
}

.language-selector .el-icon--right {
  margin-left: 5px;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  padding: 15px;
}

.wrap-login100 {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 50px 20px 50px;
}

.togglePassword {
  z-index: 5;
  right: 12px;
  top: 79%;
  transform: translateY(-50%);
  font-size: 1.1rem;
  cursor: pointer;
}

.logoo {
}

@media (min-width: 320px) and (max-width: 767px) {
  ::v-deep .el-form-item__label {
    width: 200px !important;
    text-align: left;
    float: none;
    float: unset;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
}

.text-primary {
  color: #3a3a3a;
  font-size: 1.5rem;
  font-weight: bold;
}

.text-secondary {
  color: #7d7d7d;
  font-size: 0.875rem;
}

.btn {
}

.cursor-pointer {
  cursor: pointer;
}
.reset-confirmation {
  max-width: 400px;
  /* margin: 5rem auto 0; */
  text-align: center;
  padding: 2rem;
}

.email-app-button {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #5e3cba;
  color: #fff;
  border-radius: 8px;
}
</style>
