import Resource from "./resource";
import request from "../utils/request";

class Contacts extends Resource {
  constructor() {
    super("contacts");
  }

  update(resource, id) {
    return request({
      url: `/contacts/update/${id}`,
      method: "put",
      data: resource
    });
  }
  delete_contact(id) {
    return request({
      url: `/contacts/delete/${id}`,
      method: "delete"
    });
  }
  add(resource) {
    return request({
      url: `/contacts/add_contact`,
      method: "post",
      data: resource
    });
  }
  contacts() {
    return request({
      url: `users/contacts`,
      method: "post"
    });
  }
  contactsList(query) {
    return request({
      url: `users/list/contact`,
      method: "post",
      params: query
    });
  }
  restored_contacts(resource) {
    return request({
      url: `users/list/contact`,
      method: "post",
      params: resource
    });
  }
  search_contacts(payload) {
    return request({
      url: `/users/search/contact`,
      method: "post",
      data: payload
    });
  }
  add_new_contact(id, status) {
    return request({
      url: `/users/add/contact/${id}/${status}`,
      method: "post"
    });
  }
  // for add new contact in contacts page
  add_new_contacts(payload) {
    return request({
      url: `users/add/contact`,
      method: "post",
      data: payload
    });
  }
  invite_restored_contact(payload) {
    return request({
      url: `users/invite/contact`,
      method: "post",
      data: payload
    });
  }
  // add_new_contact(id, status) {
  //   return request({
  //     url: `/users/add/contact/${id}/${status}`,
  //     method: "post"
  //   });
  // }
  change_contact_status(contact_id, status) {
    return request({
      url: `users/change/status/contact/${contact_id}/${status}`,
      method: "post"
    });
  }
  accept_invitation(id) {
    return request({
      url: `users/accept/invatation/${id}`,
      method: "post"
    });
  }
}

export { Contacts as default };
