import Course from '../../api/course'

const course = new Course();

const courseModule = {
  namespaced: true,
  state: {
    courses: [],
    teachers: [],
    load: false,
    total: 0,
    course: {},
  },
  getters: {
    courses: state => state.courses,
    teachers: state => state.teachers,
    course: state => state.course,
    load: state => state.load,
    total: state => state.total,
  },
  mutations: {
    SET_COURSES(state, courses) {
      state.courses = courses
    },
    SET_TEACHERS(state, teachers) {
      state.teachers = teachers
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
    SET_COURSE(state, course) {
      state.course = course;
    },
    SET_LOAD(state, load) {
      state.load = load;
    },
  },
  actions: {
    list({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD', true);
        course.list(payload.query).then(response => {
          commit('SET_COURSES', response.courses);
          commit('SET_TOTAL', response.count);
          commit('SET_LOAD', false);
          resolve();
        }).catch(error => {
          reject(error);
        })
      })
    },

    authCourses({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD', true);
        course.authCourses(payload.query).then(response => {
          commit('SET_COURSES', response.courses);
          commit('SET_LOAD', false);
          resolve();
        }).catch(error => {
          reject(error);
        })
      })
    },

    get({commit}, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD', true);
        course.get(id).then(response => {
          commit('SET_COURSE', response.course);
          commit('SET_TEACHERS', response.teachers);
          commit('SET_LOAD', false);
          resolve();
        }).catch(error => {
          reject(error);
        })
      })
    },
    add({commit}, payload) {
      return new Promise((resolve, reject) => {
        course.add(payload.id, payload.status)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    save({commit}, payload) {
      return new Promise((resolve, reject) => {
        course.save(payload.query, payload.id).then(response => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    evaluation({commit}, payload) {
      return new Promise((resolve, reject) => {
        course.evaluation(payload.query, payload.id).then(response => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    delete({commit}, id) {
      return new Promise((resolve, reject) => {
        course.delete(id).then(response => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
  }
}

export default courseModule;
