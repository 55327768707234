<template>
  <div class="col-lg-12 col-md-12 p-0">
    <div class="container-fluid mb-2">
      <el-alert
        class="mb3 mt-2 text-center"
        v-if="!hasPermission('access_lci')"
        type="error"
        show-icon
        :closable="false"
        effect="dark"
      >
        <template>
          <p class="my-0" style="margin-bottom: 2px !important">
            {{
              localization("update your plan") != false
                ? localization("update your plan")
                : "Kindly update your plan to access this page"
            }}
            <router-link
              to="/edit-user"
              class="text-white text-uppercase renewLink"
              >{{ localization("Renew Subscription") }}
            </router-link>
          </p>
        </template>
      </el-alert>
    </div>

    <user-card
      @select_attribute="selected_attributes"
      :selected="selected ? selected : []"
    >
    </user-card>
  </div>
</template>

<script>
import UserCard from "../../pages/Dashboard/Pages/UserProfile/UserCard";
import LAlert from "src/components/Alert";

export default {
  name: "show",
  components: { UserCard, LAlert }, // Card, Teacher
  computed: {
    id() {
      if (this.user_id) {
        return this.user_id;
      }
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    tasks() {
      return this.$store.getters["user/tasks"];
    },
    attrs() {
      return this.$store.getters["user/attrs"];
    }
  },
  data() {
    return {
      selected: [],
      panel: [0, 1, 2, 3],
      Sequence: 33,
      technical: 10,
      confluence: 20,
      precision: 25
    };
  },
  props: { user_id: null },
  mounted() {
    this.$store.dispatch("user/user", this.id).then(_ => {
      this.selected = this.$store.state.user.alluserData.selected_attributes;
    });
  },
  methods: {
    getHint(key, type) {
      switch (type) {
        case "Avoid":
          return `Since my scale score for ${key}is between 7-17, I avoid ${key}.`;
        case "As Needed":
          return `Since my scale score for ${key} is between 18-24, I use ${key} as needed.`;
        case "Use First":
          return `Since my scale score for ${key} is between 25-35, I use ${key} first.`;
        case "Sequence":
          return "Hey, you have enough Sequence to complete this task. But you might want to check out these strategies to keep your Sequence pattern from taking over.";
      }
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    getProgressValue(key) {
      switch (key) {
        case "Precision":
          return this.$store.getters["user/score"].precision;
        case "Technical Reasoning":
          return this.$store.getters["user/score"].technical_reasoning;
        case "Confluence":
          return this.$store.getters["user/score"].confluence;
        case "Sequence":
          return this.$store.getters["user/score"].sequence;
      }
    },
    selected_attributes(attributes) {
      this.$store.dispatch("user/update_attribute", {
        id: this.id,
        query: { selected_attributes: attributes }
      });
    }
  }
};
</script>
<style scoped>
.renewLink {
  text-decoration: underline;
}

.border {
  border: 2px solid white !important;
}

.letter {
  font-size: 36px;
  font-weight: 900;
  line-height: 36px;
  color: white;
}

.name {
  font-weight: 400;
  font-size: 19px;
  line-height: 1.2;
  padding-left: 0;
  color: white;
}

.number {
  color: white;
  font-weight: 100;
  font-size: 48px;
  margin-top: 0;
  text-align: right;
}

.row {
  margin-top: 0;
}

.v-expansion-panel--active > button {
  background-color: #757575 !important;
  color: #fff;
}

.v-expansion-panel--active > .theme--light .v-icon {
  color: #fff !important;
}

/*
    .child.v-expansion-panel--active > button {
      background-color: #26a1da !important;
      color: #fff;
    } */

.v-slider--horizontal .v-slider__track-container {
  height: 10px !important;
}
::v-deep .el-tree-node__content {
  height: auto !important;
}

::v-deep .el-tree-node {
  white-space: unset !important;
  outline: 0;
}
</style>
