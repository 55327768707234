import Resource from "./resource";
import request from "../utils/request";

class Words extends Resource {
  constructor() {
    super("words");
  }

  update_wall(resource, id) {
    return request({
      url: `/walls/update/${id}`,
      method: "put",
      data: resource
    });
  }

  update(resource, id) {
    return request({
      url: `/words/update/${id}`,
      method: "put",
      data: resource
    });
  }
  add_wall(resource) {
    return request({
      url: `/walls/add_wall`,
      method: "post",
      data: resource
    });
  }

  add(resource) {
    return request({
      url: `/words/add_word`,
      method: "post",
      data: resource
    });
  }

  delete_wall(id) {
    return request({
      url: `/walls/delete/${id}`,
      method: "delete"
    });
  }

  Export(resource) {
    return request({
      url: `/words/export`,
      method: "post",
      responseType: "blob",
      data: resource
    });
  }
  // bulk process
  Import_bulk(resource) {
    return request({
      url: `/words-language/import`,
      method: "post",
      "Content-Type": "multipart/form-data",
      data: resource
    });
  }
  Export_bulk() {
    return request({
      url: `words-language/export`,
      method: "post",
      responseType: "blob"
    });
  }

  walls(resource) {
    return request({
      url: `/walls`,
      method: "post",
      params: resource
    });
  }
}

export { Words as default };
