<template>
  <div class="show_print_or_delete_items">
    <div class="wrappers d-flex align-items-center">
      <span class="text">{{ text }}</span>
      <!-- <img
        role="button"
        @click="close"
        src="@/assets/new-theme/x-close.png"
        alt="close"
      /> -->
    </div>
    <div class="wrappers d-flex align-items-center">
      <img
        role="button"
        src="@/assets/new-theme/delete-button-table.png"
        alt="delete"
        @click="deleteTasks"
      />
      <img
        role="button"
        src="@/assets/new-theme/print-button-table.png"
        alt="print"
        @click="printTasks"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteTasks",
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    deleteTasks() {
      this.$emit("delete");
    },
    printTasks() {
      // window.print();
      this.$emit("print");
    }
  }
};
</script>

<style lang="scss" scoped>
.show_print_or_delete_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 20%;
  background-color: #101828;
  min-width: 400px;
  padding: 12px 24px;
  border-radius: 8px;
  z-index: 5;
  left: calc(50% - 200px);
}
.wrappers {
  gap: 15px;
  .text {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
  }
}
</style>
