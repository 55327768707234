import Resource from "./resource";
import request from "../utils/request";

class Groups extends Resource {
  constructor() {
    super("groups");
  }

  update(resource, id) {
    return request({
      url: `/groups/update/${id}`,
      method: "put",
      data: resource
    });
  }
  delete_group(id) {
    return request({
      url: `/groups/delete/${id}`,
      method: "delete"
    });
  }
  add(resource) {
    return request({
      url: `/groups/add_group`,
      method: "post",
      data: resource
    });
  }
  user_groups() {
    return request({
      url: `users/groups`,
      method: "post"
    });
  }
  group_members() {
    return request({
      url: `groups/mygroups/members`,
      method: "post",
      // params: query
    });
  }

  add_group_members() {
    return request({
      url: `users/add/group/member/520`, // group id
      method: "post"
    });
  }
  add_group_memberss(payload) {
    return request({
      url: `users/add/group`,
      method: "post",
      data: payload
    });
  }
  edit_group_memberss(payload, group_id) {
    return request({
      url: `users/edit/group/member/${group_id}`,
      method: "post",
      data: payload
    });
  }
  edit_group(payload, group_id) {
    return request({
      url: `users/edit/group/${group_id}`,
      method: "post",
      data: payload
    });
  }

  add_members_to_group(payload, group_id) {
    return request({
      url: `users/add/group/member/${group_id}`,
      method: "post",
      data: payload
    });
  }
  delete_group_member(group_id, member_id) {
    return request({
      url: `users/remove/group/member/${group_id}/${member_id}`,
      method: "post"
    });
  }
  export_group(id) {
    return request({
      url: `/groups/export/${id}`,
      method: "post",
      responseType: "blob",
      // params: query
    });
  }
  // Get Groups Institutions Members
  institutions_users() {
    return request({
      url: `groups/institution/users`,
      method: "get",
    });
  }
}
// name
// users users/add/group/member
export { Groups as default };
