<template>
  <div class="login-content">
    <div
      class="header-container d-flex justify-content-between align-items-center"
    >
      <!-- Back Arrow and Title -->
      <div class="d-flex align-items-center for-backing">
        <div>
          <i @click="forgot = false" class="el-icon-back go-back"></i>
        </div>
        <div class="fw-bold" v-if="!forgot">
          {{ publicLocalization("Login") }}
        </div>
        <div class="fw-bold" v-else-if="!showConfirmation">
          {{ publicLocalization("Forgot password") }}
        </div>
        <div class="fw-bold" v-else-if="showConfirmation">
          Email sent
        </div>
      </div>

      <!-- Language Selector Dropdown -->
      <el-dropdown class="language-selector">
        <el-button>
          <img
            src="https://hatscripts.github.io/circle-flags/flags/us.svg"
            alt="US Flag"
            width="20"
            height="20"
            class="mr-2"
          />
          English <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="en">
            <img
              src="https://hatscripts.github.io/circle-flags/flags/us.svg"
              alt="US Flag"
              width="20"
              height="20"
              class="mr-2"
            />
            English
          </el-dropdown-item>
          <el-dropdown-item command="fr">
            <img
              src="https://hatscripts.github.io/circle-flags/flags/fr.svg"
              alt="French Flag"
              width="20"
              height="20"
              class="mr-2"
            />
            French
          </el-dropdown-item>
          <el-dropdown-item command="es">
            <img
              src="https://hatscripts.github.io/circle-flags/flags/es.svg"
              alt="Spanish Flag"
              width="20"
              height="20"
              class="mr-2"
            />
            Spanish
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- <div class="wrap-login100 p-4"> -->
    <el-row>
      <el-col :span="12" class="bg-white rounded-start">
        <fade-render-transition
          :duration="500"
          class="pt-3 pb-5"
          style="width: 68%; margin:auto"
        >
          <!-- Login Form  -->
          <el-form
            v-model="login"
            :model="login"
            v-if="!forgot"
            @submit.prevent="submit"
            class="p-4 w-90"
          >
            <!-- Start Dropdown Language  -->
            <!-- <div class="drop-language">
              <el-select
                class="select-default"
                v-model="selectedLanguageId"
                :placeholder="`${publicLocalization('Language')}`"
                @change="switchLocalization()"
              >
                <el-option
                  v-for="item in publishedLanguages"
                  :key="item.id"
                  :value="item.title"
                >
                </el-option>
              </el-select>
            </div> -->
            <!-- End Dropdown Language  -->

            <!-- Start Logo  -->
            <!-- <div class="mb-2"> -->
            <h2 class="fw-bold mb-3 h2">
              {{ publicLocalization("Login") }}
            </h2>

            <div :class="{ is_arabic: isRtl == true }">
              <P class="text-muted mb-4">
                {{ publicLocalization("welcome back") }}
              </P>

              <el-alert
                title="If you are logging in to the new site for the first time, you will need to reset your password"
                type="info"
                show-icon
                class="custom-alert "
              >
                <el-link
                  type="primary"
                  @click="forgot = true"
                  class="custom-link"
                  :underline="false"
                >
                  <!-- {{ publicLocalization("Reset your password") }} → -->
                  Reset your password
                  <i
                    @click="forgot = false"
                    class="el-icon-back rotate-icon"
                  ></i>
                </el-link>
              </el-alert>
            </div>
            <!-- </div> -->

            <!-- Enter Mail Input  -->
            <div>
              <el-form-item
                :label="`${publicLocalization('Email')}`"
                prop="email"
                :rules="[
                  {
                    required: true,
                    message: `${$t('validation.Enter your email')}`,
                    trigger: 'blur'
                  },
                  {
                    type: 'email',
                    message: `${$t('validation.Enter your email')}`,
                    trigger: ['blur', 'change']
                  }
                ]"
                class="every-item"
                :class="{ is_arabic: isRtl == true }"
              >
                <el-input
                  type="email"
                  v-model="login.email"
                  :placeholder="`${publicLocalization('Enter your email')}`"
                  style="width: 100%"
                  clear="inputs-weap"
                  class="just_pass_inarabic"
                ></el-input>
              </el-form-item>
            </div>

            <!-- Enter Password Input -->
            <div>
              <el-form-item
                :label="`${publicLocalization('password')}`"
                prop="password"
                :rules="[
                  {
                    required: true,
                    message: 'Enter your password',
                    trigger: 'blur'
                  }
                ]"
                class="every-item position-relative"
                :class="{ is_arabic: isRtl == true }"
              >
                <i
                  v-if="passwordType == 'password'"
                  class="
                      fa fa-eye
                      position-absolute
                      togglePassword
                      text-secondary
                    "
                  @click="toggleShowPassword"
                  aria-hidden="true"
                  v-tooltip.top-center="
                    `${publicLocalization('Show Password')}`
                  "
                ></i>
                <i
                  v-if="passwordType == 'text'"
                  class="
                      fa fa-eye-slash
                      position-absolute
                      togglePassword
                      text-secondary
                      curoser
                    "
                  v-tooltip.top-center="
                    `${publicLocalization('Hide Password')}`
                  "
                  @click="toggleShowPassword"
                  aria-hidden="true"
                ></i>
                <el-input
                  :type="passwordType"
                  v-model="login.password"
                  :placeholder="`${publicLocalization('Enter your password')}`"
                  style="width: 100%"
                  clear="inputs-weap"
                  class="just_pass_inarabic"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <div
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <el-checkbox
                    v-model="login.rememberMe"
                    class="custom-checkbox"
                    >Remember me</el-checkbox
                  >
                  <el-link
                    type="primary"
                    @click="forgot = true"
                    :underline="false"
                    style="
                  color: #6941C6;
                  "
                    >{{ publicLocalization("Forgot your password") }}
                  </el-link>
                </div>
              </el-form-item>
            </div>

            <!-- <div class=" Forgot-password mb-2">
              <a class="txt" href="#" @click="forgot = true">
                {{ publicLocalization("Forgot your password") }}
              </a>
            </div> -->
            <el-form-item>
              <el-button
                @click="submit"
                plain
                type="info"
                class="custom-button w-100 "
              >
                {{ publicLocalization("Login") }}
              </el-button>
            </el-form-item>
            <!-- Forgot password button  -->

            <!-- Create new account button  -->
            <div
              class="text-center new-account pb-3 mt-4"
              :class="{ is_arabic: isRtl == true }"
            >
              {{ publicLocalization("Don’t have an account") }}
              <a
                :class="{ is_arabic: isRtl == true }"
                class="txt2"
                style="cursor: pointer; color: #6941C6;
"
                @click="$router.push('/register')"
                >{{ publicLocalization("Sign Up") }}
                <!-- <i
                  v-if="isRtl == false"
                  class="fa fa-long-arrow-right m-l-5"
                  aria-hidden="true"
                ></i>
                <i
                  v-if="isRtl == true"
                  class="fa fa-long-arrow-left m-l-5"
                  aria-hidden="true"
                ></i> -->
              </a>
            </div>
          </el-form>
          <!-- End Login Form  -->

          <!-- Form Reset password email  -->
          <div v-if="!showConfirmation">
            <el-form
              v-model="reset"
              :model="reset"
              :rules="resetRules"
              @submit.prevent="sendResetMail"
              style="max-width: 400px; width: 100%;text-align: left;"
              ref="resetRef"
            >
              <div class="content" style="margin-top:15rem">
                <div class="mb-2">
                  <h2
                    style="color: #1f1f1f; font-weight: 600; font-size: 30px;"
                  >
                    {{ publicLocalization("Forgot password") }}
                  </h2>
                  <p style="color: #6b6b6b; font-size: 14px;">
                    {{
                      publicLocalization(
                        "Please enter your email to get the reset email."
                      )
                    }}
                  </p>
                </div>

                <div class="main-forgetpass">
                  <el-form-item
                    :label="`${publicLocalization('Email')}`"
                    prop="email"
                    label-width="forget-pass-label"
                    style="width: 100%;"
                  >
                    <el-input
                      type="email"
                      v-model="reset.email"
                      @keyup.enter.native="sendResetMail"
                      :placeholder="`${publicLocalization('Enter your email')}`"
                      style=""
                    ></el-input>
                  </el-form-item>
                </div>

                <div class="my-3">
                  <el-button
                    @click="sendResetMail"
                    type="primary"
                    class=""
                    style="
                  width: 100%;
                  padding: 12px;
                  background-color: #5e3cba;
                  color: #fff;
                  border-radius: 8px;
                  font-weight: 600;
                  margin:8px 0;
                "
                  >
                    {{ publicLocalization("Send reset email") }}
                  </el-button>
                </div>

                <div
                  style="font-size: 14px; color: #6b6b6b;"
                  class="text-center"
                >
                  <span>{{
                    publicLocalization("Don't have an account ?")
                  }}</span>
                  <a
                    href="/register"
                    style="color: #5e3cba; text-decoration: none;"
                  >
                    {{ publicLocalization("Sign up") }}</a
                  >
                </div>
              </div>
            </el-form>
          </div>
          <div v-else class="content">
            <div class="reset-confirmation text-center">
              <img
                src="../../../assets/images/icons/message.png"
                alt="Email Icon"
                class="email-icon"
              />
              <h2>Check your email</h2>
              <p class="text-muted">
                We sent a password reset link to
                <span class="mail-text">{{ reset.email }}</span>
              </p>
              <el-button
                type="primary"
                class="email-app-button"
                @click="openEmailApp"
              >
                Open email app
              </el-button>
              <p class="resend">
                Didn't receive the email?
                <a href="#" @click.prevent="resendEmail">Click to resend</a>
              </p>
              <el-link
                :underline="false"
                @click="backToForm"
                class="back-to-login"
              >
                <i class="el-icon-back"></i>
                Back to log in
              </el-link>
            </div>
          </div>
        </fade-render-transition>
      </el-col>
      <el-col :span="12" class="bg-light d-none d-md-block rounded-end">
        <div class="">
          <img
            src="../../../assets/images/frames/Frame 90.png"
            alt="Dashboard preview"
            class="img-fluid rounded w-100"
          />
        </div>
      </el-col>
    </el-row>
    <!-- <div>{{ $store.getters["cms/getShowpublicLocalizationRegisterPage"] }}</div> -->
    <!-- </div> -->
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-logo">
          <img
            src="../../../assets/images/logo/PLC-Logo.png"
            alt="Personal Learning Coach Logo"
          />
        </div>
        <div class="footer-text">
          Copyright © 2024 - PLC - All Rights Reserved - Developed by VERO -
          Designed by UIX Studio
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { FadeRenderTransition } from "src/components/index";
import Swal from "sweetalert2";
// 4mha2XNAn7FNNjNXX9x8
export default {
  name: "login",
  data() {
    return {
      isloginForm: false,
      forgot: false,
      showConfirmation: false,
      passwordType: "password",
      login: {},
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        }
      },
      reset: {
        email: ""
      },
      resetRules: {
        email: [
          {
            required: true,
            message: "Enter your email",
            trigger: "blur"
          }
        ]
      },
      isArabic: false,
      selectedLanguageId: null,
      isRtl: false // Language Direction
    };
  },
  mounted() {
    // window.addEventListener("keypress", (e) => {
    //   if (e.key == "Enter") {
    //     if (!this.forgot) {
    //       this.submit();
    //     } else {
    //       this.sendResetMail();
    //     }
    //   }
    // });

    // Get Published Language
    this.$store.dispatch("language/getLanguages");
    this.$store.dispatch("cms/getLocalizationForRegister", {
      id: null
    });
    // this.$store.dispatch("cms/getEverypublicLocalization", {
    //   id: null
    // });
  },
  components: {
    FadeRenderTransition
  },
  computed: {
    ar_lang() {
      return this.isArabic;
    },
    languageDirection() {
      return this.isRtl;
    },
    publishedLanguages() {
      return this.$store.getters["language/languages"];
    }
  },
  methods: {
    openEmailApp() {
      const email = this.reset.email;
      if (email) {
        window.location.href = `https://mail.google.com/mail/u/0/#inbox`;
      } else {
        console.error("Email not provided");
      }
    },
    toggleShowPassword() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    submit() {
      if (this.login.email && this.login.password) {
        this.$store.dispatch("user/login", this.login).then(res => {
          let userData = res.user;
          if (
            userData.confluence != null &&
            userData.precision != null &&
            userData.sequence != null &&
            userData.technical_reasoning != null
          ) {
            this.$router.push("/my-dashboard");
          } else {
            this.$router.push("/");
          }
          console.log(userData);
        });
      }
    },
    sendResetMail() {
      this.$refs["resetRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/forgotPassword", {
              forgotEmail: {
                email: this.reset.email
              }
            })
            .then(() => {
              //   Swal.fire({
              //     icon: "success",
              //     title: `${this.publicLocalization(
              //       "Forgot Password Confirmation"
              //     )}`,
              //     showConfirmButton: false,
              //     showCloseButton: true,
              //     timer: 4000
              //   });
              this.showConfirmation = true;
            });
        } else {
          console.log("error submitting");
          return false;
        }
      });
    },
    backToForm() {
      this.showConfirmation = false;
    },
    switchs(local) {
      this.$i18n.locale = local;
      // if (this.$i18n.locale == "ar") {
      //   this.isArabic = true;
      // } else {
      //   this.isArabic = false;
      // }
    },
    switchLocalization() {
      this.$store
        .dispatch("cms/getEveryLocalizationForRegisterPages", {
          language: this.selectedLanguageId
        })
        .then(() => {
          this.publishedLanguages.filter(item => {
            if (item.id == this.selectedLanguageId) {
              console.log(item.rtl);
              console.log(item);
              if (item.rtl == 1) {
                this.isRtl = true;
              } else {
                this.isRtl = false;
              }
            }
          });
          // console.log(this.publishedLanguages);
          // this.selectedLanguageId
        });
    }
  }
};
</script>

<style scoped lang="scss">
.custom-checkbox .el-checkbox__inner {
  border-color: #7f56d9 !important;
}

.custom-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #7f56d9 !important;
  border-color: #7f56d9 !important;
}

.custom-checkbox .el-checkbox__input.is-checked .el-checkbox__inner::after {
  background-color: #fff !important;
}
.custom-button {
  // background: #7F56D9;
  // color: #fff;
  border-color: transparent;
}

.custom-button:hover {
  background: #5e3cba;
  color: #fff;
}
.custom-alert {
  background-color: #f7f9fc;
  border: 1px solid #dfe3e8;

  border-radius: 8px;
  font-size: 16px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.custom-alert .el-alert__title {
  font-weight: normal;
}

.custom-alert .el-alert__icon {
  color: #8e8e8e;
  font-size: 16px !important;
  width: 16px !important;
}
.custom-link {
  color: #667085;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.custom-link:hover {
  text-decoration: none;
  color: #6941c6;
}

.login-content {
  font-family: "Poppins", sans-serif;
}
.header-container {
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0; /* Light gray border for separation */
  background-color: #ffffff; /* White background */
}

.header-container .fw-bold {
  font-size: 22px;
  color: #333333;
  font-weight: 500;
}

.language-selector {
  display: flex;
  align-items: center;
}

.language-selector .el-button {
  border-radius: 8px;
  padding: 9px 10px;
  font-size: 14px;
  color: #333333; /* Text color */
  background-color: #f9f9f9; /* Light background color */
  display: flex;
  align-items: center;
  gap: 8px; /* Space between flag icon and text */
}

.language-selector img {
  border-radius: 50%; /* Circular flag icons */
  margin-right: 1px;
  margin-bottom: 3px;
}

.language-selector .el-icon--right {
  margin-left: 5px;
}

// footer
.footer {
  background-color: #f7f9fa; /* Light background color */
  padding: 24px 32px;
  text-align: center;
  font-size: 14px;
  color: #8c8c8c; /* Light gray text color */
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

// .footer-logo img {
//   width: 30px;
//   height: 30px;
// }

.footer-text {
  font-size: 14px;
  color: #8c8c8c;
}

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600&display=swap");
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  // justify-content: center;
  // align-items: center;
  padding: 15px;
  // background: url("/static/img/full-screen-image-4.jpg") no-repeat center;
  // background-size: cover;
  // background-attachment: fixed;
  // background-position: center center;
  &.is_arabic {
    font-family: "Cairo", sans-serif;
  }
}

.wrap-login100 {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 50px 20px 50px;
  padding: 0 50px 20px 50px;
  &.is_arabic {
    font-family: "Cairo", sans-serif;
  }
  .card-title {
    margin-top: 0 !important;
  }
  .wrap-of-text {
    font-size: 16px;
    color: #909399;
    text-align: center;
    &.is_arabic {
      direction: rtl;
      color: red !important;
      font-family: "Cairo", sans-serif;
    }
  }
  .every-item {
    width: 60%;
    margin: 20px auto;
    @media (min-width: 320px) and (max-width: 567px) {
      width: 100%;
    }
  }
}
::v-deep .el-input__inner {
  padding: 22px 7px !important;
  transition: 0.3s all ease-in-out;
  background-color: #fff !important;
  border: 1px solid #dee0e6;
  &:focus,
  .is-active,
  &:hover {
    background-color: #fff !important;
    border-color: #23ccef;
  }
}
// ? asterisks
::v-deep {
  .el-form-item {
    .el-form-item__label {
      width: 100%;
      text-align: left;
      // &::before {
      //   content: "" !important;
      // }
    }
    &.is_arabic {
      .el-form-item__label {
        text-align: right;
        font-family: "Cairo", sans-serif;
      }
      .el-form-item__content {
        .just_pass_inarabic {
          direction: rtl;
        }
      }
      .togglePassword {
        left: 12px !important;
        right: auto !important;
      }
    }
  }
}
.main-forgetpass {
  // width: 60%;
  // margin: 15px auto 0;
  @media (min-width: 320px) and (max-width: 567px) {
    width: 100%;
  }
}
.Forgot-password {
  width: 60%;
  margin: 15px auto 0;
  text-align: right;
  @media (min-width: 320px) and (max-width: 567px) {
    width: 100%;
  }
  .txt {
    color: #e63b45 !important;
    font-size: 14px;
    transition: 0.3s all ease-in-out;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
.new-account {
  color: #909399;
  &.is_arabic {
    direction: rtl;
    font-family: "Cairo", sans-serif;
  }
  .text2 {
    color: #23ccef;
    font-size: 14px;
    transition: 0.3s all ease-in-out;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
.txt2 {
  color: #23ccef;
  font-size: 14px;
  transition: 0.3s all ease-in-out;
  opacity: 0.7;
  &.is_arabic {
    font-family: "Cairo", sans-serif !important;
  }
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.lgin-btn {
  transition: 0.3s all ease-in-out;
  opacity: 0.7;
  background-color: #23ccef;
  border: none;
  outline: none;
  color: #ffff;
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    opacity: 1;
  }
}
.submit_lci_buttons {
  background: #00563f;
}
.togglePassword {
  z-index: 5;
  right: 12px;
  top: 75%;
  transform: translateY(-50%);
  font-size: 1.1rem;
  cursor: pointer;
}
.ring-icon {
  color: #e63b45;
}
// .logos {
//   width: 300px;
//   height: 220px;
// }

.for-backing {
  position: relative;
  .goo-back {
    background-color: #00563f;

    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
  .go-back {
    font-weight: 700;
    line-height: 2;
    margin-right: 13px;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
.btn-forgetpass {
  background-color: #23ccef;
  border-color: #23ccef;
  color: #fff;
}
.drop-language {
  display: flex;
  flex-direction: row-reverse;
}
.drow-down {
  width: 100px !important;
  text-align: center;
  .el-dropdown-menu__item {
    padding: 0;

    span {
      width: 100%;
      display: block;
      transition: 0.3s all ease-in-out;
      &:hover {
        background-color: #23ccef;
        color: #fff;
      }
    }
  }
}
::v-deep .select-default.el-select .el-input:hover input {
  background-color: #888888 !important;
}
// .submit_lci_buttons {
//   background: #00563f;
// }
// .ttete {
//   background-color: #3c3;
//   background-color: #f93;
//   display: inline;
//   background-color: #39c;
//   background-color: #c36;
// }
$width: 25%;
.login_what_patterns {
  .text_patterns_box {
    width: 80%;
    margin: auto;
    // background: aqua;
    text-align: center;
    font-size: 2.7rem;
    font-weight: bold;
    text-transform: uppercase;
    border: 5px solid transparent;
    // border-left-color: #39c;
    // border-right-color: #c36;
    color: #777;
    position: relative;
    @media (min-width: 700px) and (max-width: 991px) {
      width: 100%;
      font-size: 2.3rem;
    }
    @media (min-width: 460px) and (max-width: 700px) {
      width: 100%;
      font-size: 1.5rem;
    }
    @media (min-width: 320px) and (max-width: 460px) {
      width: 100%;
      font-size: 0.9rem;
    }
    // pat_text
    &::before {
      position: absolute;
      content: "";
      left: 0;
      height: 100%;
      width: 5px;
      background-color: #39c;
    }
    &::after {
      position: absolute;
      content: "";
      right: 0;
      height: 100%;
      width: 5px;
      background-color: #c36;
    }

    .pat_one {
      position: absolute;
      top: -5px;
      left: 0;
      height: 5px;
      width: $width;
      background-color: #39c;
    }

    .pat_two {
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 5px;
      width: $width;
      background-color: #39c;
    }
    .pat_three {
      position: absolute;
      top: -5px;
      left: $width;
      height: 5px;
      width: $width;
      background-color: #3c3;
    }
    .pat_four {
      position: absolute;
      bottom: -5px;
      left: $width;
      height: 5px;
      width: $width;
      background-color: #3c3;
    }

    .pat_five {
      position: absolute;
      top: -5px;
      left: $width * 2;
      height: 5px;
      width: $width;
      background-color: #f93;
    }
    .pat_six {
      position: absolute;
      bottom: -5px;
      left: $width * 2;
      height: 5px;
      width: $width;
      background-color: #f93;
    }

    .pat_seven {
      position: absolute;
      top: -5px;
      left: $width * 3;
      height: 5px;
      width: $width;
      background-color: #c36;
    }
    .pat_eight {
      position: absolute;
      bottom: -5px;
      left: $width * 3;
      height: 5px;
      width: $width;
      background-color: #c36;
    }
  }
}
.rotate-icon {
  transform: rotate(180deg);
  font-size: 1rem;
}
.el-alert__icon.is-big {
  font-size: 20px !important;
  width: 20px !important;
}
.reset-confirmation {
  max-width: 400px;
  margin: 12rem auto 0;
  text-align: center;
  padding: 2rem;
}
.email-icon {
  width: 65px;
}
.mail-text {
  color: rgba(102, 112, 133, 1);
}
.email-app-button {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #5e3cba;
  color: #fff;
  border-radius: 8px;
}
.resend {
  margin-top: 1rem;
  font-size: 14px;
  color: #6b6b6b;
}
.resend a {
  color: #5e3cba;
  text-decoration: none;
}
.back-to-login {
  display: inline-block;
  margin-top: 1rem;
  // color: #5e3cba;
  font-weight: bold;
}

.back-to-login:hover {
  color: #5e3cba;
}
</style>
