import Resource from "./resource";
import request from "../utils/request";

class Cms extends Resource {
  constructor() {
    super("cms");
  }

  update(resource, id) {
    return request({
      url: `/cms/update/${id}`,
      method: "put",
      data: resource
    });
  }
  add(resource) {
    return request({
      url: `/cms/add`,
      method: "post",
      data: resource
    });
  }

  delete(id) {
    return request({
      url: `/cms/delete/${id}`,
      method: "delete"
    });
  }

  localizationList(resource) {
    return request({
      url: `/localization`,
      method: "post",
      params: resource
    });
  }
  showLocalization(id) {
    if (id != null) {
      return request({
        url: `/localization/show/${id}`,
        method: "get",
      });
    } else{
      return request({
        url: `/localization/show`,
        method: "get",
      });
    }
  }

  editLocalization(resource, id) {
    return request({
      url: `/localization/save/${id}`,
      method: "put",
      data: resource
    });
  }

  getLocalizationForRegistration(payload) {
    return request({
      url: `/localization/website/localization`,
      method: "post",
      data: payload
    });
  }

  exportLocalization(id) {
    return request({
      url: `/localization/export/${id}`,
      method: "post",
      responseType: "blob",
    });
  }

}

export { Cms as default };
