<template>
  <div>
    <div
      class="header-container d-flex justify-content-between align-items-center"
    >
      <!-- Back Arrow and Title -->
      <div class="d-flex align-items-center for-backing">
        <div>
          <i @click="$router.go(-1)" class="el-icon-back go-back"></i>
        </div>
        <div class="fw-bold d-flex align-items-center gap">
          Sign Up - Step {{ currentStep }}
          <span class="student-plan-label">
            {{ plan_type }}
          </span>
        </div>
      </div>

      <!-- Language Selector Dropdown -->
      <el-dropdown class="language-selector">
        <el-button>
          <img
            src="https://hatscripts.github.io/circle-flags/flags/us.svg"
            alt="US Flag"
            width="20"
            height="20"
            class="mr-2"
          />
          English <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="en">
            <img
              src="https://hatscripts.github.io/circle-flags/flags/us.svg"
              alt="US Flag"
              width="20"
              height="20"
              class="mr-2"
            />
            English
          </el-dropdown-item>
          <el-dropdown-item command="fr">
            <img
              src="https://hatscripts.github.io/circle-flags/flags/fr.svg"
              alt="French Flag"
              width="20"
              height="20"
              class="mr-2"
            />
            French
          </el-dropdown-item>
          <el-dropdown-item command="es">
            <img
              src="https://hatscripts.github.io/circle-flags/flags/es.svg"
              alt="Spanish Flag"
              width="20"
              height="20"
              class="mr-2"
            />
            Spanish
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="tabs_wrapper">
      <div class="tabs_steps mx-3">
        <span
          v-for="tab in tabs"
          :key="tab.index"
          class="d-block step"
          :class="{ active: tab.index == currentStep }"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true
    },
    currentStep: null,
    plan_type: null
  }
};
</script>

<style scoped lang="scss">
.header-container {
  padding: 19px 20px 10px;
  background-color: #ffffff;
}

.header-container .fw-bold {
  font-size: 22px;
  color: #333333;
  font-weight: 500;
}

.language-selector {
  display: flex;
  align-items: center;
}

.language-selector .el-button {
  border-radius: 8px;
  padding: 9px 10px;
  font-size: 14px;
  color: #333333; /* Text color */
  background-color: #f9f9f9; /* Light background color */
  display: flex;
  align-items: center;
  gap: 8px; /* Space between flag icon and text */
}

.language-selector img {
  border-radius: 50%; /* Circular flag icons */
  margin-right: 1px;
  margin-bottom: 3px;
}

.language-selector .el-icon--right {
  margin-left: 5px;
}

.for-backing {
  position: relative;
  .goo-back {
    background-color: #00563f;

    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
  .go-back {
    font-weight: 700;
    line-height: 2;
    margin-right: 13px;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

// Tabs
.tabs_wrapper {
  flex-grow: 1;
  // background: #eee;
  .step_number_and_questions_number {
    display: flex;
    align-items: center;
    gap: 10px;
    .st_number_value {
      color: #101828;
      font-size: 22px;
      font-weight: 500;
    }
    .st_questions_number {
      color: #667085;
      font-size: 14px;
    }
    .st_number_value {
      p {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }
  .tabs_steps {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    .step {
      height: 4px;
      border-radius: 8px;
      background-color: #e9d7fe;
      flex-grow: 1;
      // width: 180px;
      &.active {
        background-color: #7f56d9;
      }
    }
  }
}
.gap {
  gap: 10px;
}
.student-plan-label {
  background-color: #f6f1fe;
  color: #7f56d9;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 15px;
  padding: 5px 10px;
}
</style>
