import Resource from '../api/resource';
import request from '../utils/request';
import store from '../store/store';

class Course extends Resource {
  constructor() {
    super('courses');
  }

  save(resource, id) {
    return request({
      url: `/courses/save${id}`,
      headers: {'Content-Type': 'multipart/form-data'},
      method: 'post',
      data: resource,
      onUploadProgress: function (progressEvent) {
        store.commit('SET_UPLOAD_PROGRESS', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
      }.bind(store),
    });
  }

  authCourses(query){
    return request({
      url: `/auth/courses`,
      method: 'post',
      params: query,
    })
  }

  evaluation(resource, id) {
    return request({
      url: `/evaluation/save${id}`,
      headers: {'Content-Type': 'multipart/form-data'},
      method: 'post',
      data: resource,
      onUploadProgress: function (progressEvent) {
        store.commit('SET_UPLOAD_PROGRESS', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
      }.bind(store),
    });
  }

  add(id, status) {
    return request({
      url: `/courses/add/${id}/${status}`,
      method: 'post',
    })
  }


}

export {Course as default};
