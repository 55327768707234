import Resource from "./resource";
import request from "../utils/request";

class Language extends Resource {
  constructor() {
    super("languages");
  }

  switch(id) {
    return request({
      url: `/languages/switch/${id}`,
      method: "post"
    });
  }

  getLanguages() {
    return request({
      url: `/langs`,
      method: "post"
    });
  }

  togglePublish(id){
  return request({
    url: `/languages/publish/${id}`,
    method: "post"
  });
  }
}

export { Language as default };
