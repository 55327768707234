<template>
  <div class="w-100 m-0 p-0">
    <div class="alert alert-warning alert-custom" role="alert">
      <div class="d-flex">
        <span class="mr-2">
          <img src="@/assets/new-theme/warning.png" alt="" />
        </span>
        <div>
          <div>
            <strong>We have sent an invitation to all invited members. Ask them to accept the invitation and complete the required steps.</strong><br>
            Once the team status is changed to In progress, review what your team members bring and need, and then write your statement about how you will work with THIS team.
          </div>
          <div>
            <a href="#" class="mt-3 d-inline-block text-warning font-weight-bold">Back to teams &rarr;</a>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h4 class="mt-5">Check the team members’ answers </h4>
      <!-- tabs -->
      <div>
        <template>
          <el-tabs type="border-card">
            <el-tab-pane label="Invited members">
              <div class=" mt-5 ">
                  <div class="main_table">
                    <TableHeaderSection
                      @toggleModal="ToggleTaskModal('add')"
                      title="Team members"
                      :badge="team_members.length +' members'" 
                      text="See all team members' learning patterns or add new members to your team."
                      buttonText="Add new member"
                      :total="$store.getters['user/tasks_count']"
                    ></TableHeaderSection>
                    <div class="header_container just_custom_table_tasks">
                      <CustomTable
                        :count="team_members.length"
                        :DataItems="team_members"
                        @Refresh="refresh"
                        @reset="filter = {}"
                        :filter="filter"
                        :title="'Tasks'"
                        hidden_export_import
                        admin_permission="admin_tasks"
                        edit_permission="add_tasks"
                        class="team_members"
                        @selection="selections"
                      >
                        <template #columns>
                          <el-table-column type="selection" width="80"></el-table-column>
                          <el-table-column prop="first_name" sortable :label="`${localization('Member Name')}`">
                            <template slot-scope="props">
                              <div>
                                <span>{{ props.row.first_name }} </span>
                                <span>{{ props.row.last_name }}</span>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="sequence" sortable :label="`${localization('sequence')}`">
                            <template slot-scope="props">
                              <span class="text-white bg-info rounded-pill px-4 py-2">{{ props.row.sequence }} - {{ props.row.classification }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column prop="precision" sortable :label="`${localization('Precision')}`">
                            <template slot-scope="props">
                              <span class="text-white bg-success rounded-pill px-4 py-2">{{ props.row.precision }} - {{ props.row.classification }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column prop="technical_reasoning " sortable :label="`${localization('Technical Reasoning ')}`">
                            <template slot-scope="props">
                              <span class="text-white bg-warning rounded-pill px-4 py-2">{{ props.row.technical_reasoning }} - {{ props.row.classification }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column prop="confluence" sortable :label="`${localization('Confluence')}`">
                            <template slot-scope="props">
                              <span class="text-white bg-danger rounded-pill px-4 py-2">{{ props.row.confluence }} - {{ props.row.classification }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column prop width="auto">
                            <template slot-scope="scope">
                              <div class="td_actions flex justify-content-end">
                                <!-- show task button  -->
                                <router-link
                                  v-if="hasPermission('show_tasks')"
                                  :to="`#`"
                                  v-tooltip.top-center="
                                      `${localization('Show')}`
                                    "
                                  class="btn btn-info btn-link btn-xs"
                                >
                                  <img src="@/assets/new-theme/eye.png" alt="eye" />
                                </router-link>
                                <span class="btn btn-warning btn-simple btn-link"
                                  v-tooltip.top-center="
                                    `${localization('Delete')}`
                                  "
                                >
                                  <img src="@/assets/new-theme/trash.png" alt="delete" />
                                </span>
                              </div>
                            </template>
                          </el-table-column>
                        </template>
                      </CustomTable>
                    </div>
                  </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Bring to a team">Bring to a team</el-tab-pane>
            <el-tab-pane label="Need from a team">Need from a team</el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </div>
  </div>
</template>
  
<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ChartCard, StatsCard } from "src/components/index";
export default {
  data() {
    return {
    
    };
  },
  props: {
    teamId: {
      default: null
    },
  },
  components: {
    Card,
    ChartCard,
    StatsCard
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      team_details: ["user/get_team_details"],
      team_members: ["user/get_team_members"],
      invited_team_members: ["user/get_invited_team_members"]
    }),
  },
  data(){
    return {
      selected: [],
    }
  },
  mounted() {
    this.get_team_details();  
    console.log('______________________-- >>>>>>>>>.. ', this.teamId);
    
  },
  methods: {
    get_team_details() {
      this.$store.dispatch("user/every_team_members", this.teamId);
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
      console.log(this.selected);
      // this.selectedInst.users = Array.from(selections, el => el.id);
    },
  }
};
</script>
<style scoped lang="scss">
.alert-custom {
  background-color: #fffaf0; /* Light background similar to your image */
  border: 1px solid #ffd580; /* Border color to match */
  color: #c35a00; /* Text color to match the orange */
  border-radius: 10px;
}

.alert-custom i {
  color: #c35a00; /* Icon color */
  font-size: 20px;
}

.alert-custom a {
  color: #c35a00;
  text-decoration: none;
}

.alert-custom a:hover {
  text-decoration: underline;
}

::v-deep .el-tabs--border-card, .el-tabs__content{
  border: unset !important;
}

::v-deep .el-tabs__nav-scroll{
  padding: 10px !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  border-top-color: #DCDFE6 !important;
  border-bottom-color: #DCDFE6 !important;
  border-radius: 7px !important;
}

::v-deep .team_members{
  .el-table .cell{
    overflow: unset !important;
  }
}

::v-deep.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  color: #344054;
}

::v-deep .el-tabs--border-card{
  box-shadow: unset !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header{
  border-bottom: unset !important;
}
</style>