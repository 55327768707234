var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[_c('table',{staticClass:"table table-striped text_nobreak w-100"},[_c('tbody',[_vm._t("other_data"),_vm._l((_vm.items),function(item,key){return _c('tr',{key:key,staticClass:"w-100"},[_c('div',{staticClass:"just_wrapping"},[_c('div',{staticClass:"part_a"},[_c('th',{staticClass:"text-capitalize",attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(item.key == "first_name" ? "First Name" : item.key == "last_name" ? "last Name" : item.key == "group_team" ? "Group Team" : item.key == "team_introduce" ? "Team Introduce" : item.key == "team_create" ? "Team Create" : item.key == "team_work" ? "Team Work" : item.key == "team_project" ? "Team Project" : item.key)+" ")])]),_c('div',{staticClass:"part_b"},[_c('td',[_c('span',[_vm._t("custom_show")],2),(
                  item.key.includes('date') ||
                    item.key.includes('close_at') ||
                    item.key.includes('ends_at') ||
                    item.key.includes('starts') ||
                    item.key.includes('expiration') ||
                    item.key.includes('start') ||
                    item.key.includes('created_at') ||
                    item.key.includes('updated_at') ||
                    item.key === 'from' ||
                    item.key === 'to'
                )?_c('span',[_c('i',{staticClass:"el-icon-time"}),_vm._v(" "+_vm._s(_vm.toLocalDatetime(item.value, "en")))]):_c('span',{staticClass:"text-break"},[_vm._v(" "+_vm._s(item.value)+" ")])])])])])}),_vm._t("bottom_other_data")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }