<template>
  <card>
    <table class="table table-striped text_nobreak w-100">
      <tbody>
        <slot name="other_data"></slot>
        <tr v-for="(item, key) in items" :key="key" class="w-100">
          <div class="just_wrapping">
            <div class="part_a">
              <!-- <th scope="row" :width="'30%'"> class="text-capitalize"-->
              <th scope="row" class="text-capitalize">
                <!-- {{ item.key == "first_name" ? "First Name" : item.key }} -->
                {{
                  item.key == "first_name"
                    ? "First Name"
                    : item.key == "last_name"
                    ? "last Name"
                    : item.key == "group_team"
                    ? "Group Team"
                    : item.key == "team_introduce"
                    ? "Team Introduce"
                    : item.key == "team_create"
                    ? "Team Create"
                    : item.key == "team_work"
                    ? "Team Work"
                    : item.key == "team_project"
                    ? "Team Project"
                    : item.key
                }}
              </th>
            </div>
            <div class="part_b">
              <td>
                <span>
                  <slot name="custom_show"></slot>
                </span>
                <!--            <span v-if="typeof item.value === 'string' && item.value.search(/\n/g) > 0"-->
                <!--                  v-html="ntBr(item.value)"></span>-->
                <span
                  v-if="
                    item.key.includes('date') ||
                      item.key.includes('close_at') ||
                      item.key.includes('ends_at') ||
                      item.key.includes('starts') ||
                      item.key.includes('expiration') ||
                      item.key.includes('start') ||
                      item.key.includes('created_at') ||
                      item.key.includes('updated_at') ||
                      item.key === 'from' ||
                      item.key === 'to'
                  "
                  ><i class="el-icon-time"></i>
                  {{ toLocalDatetime(item.value, "en") }}</span
                >

                <span class="text-break" v-else>
                  {{ item.value }}
                </span>
              </td>
            </div>
          </div>
        </tr>
        <slot name="bottom_other_data"></slot>
      </tbody>
    </table>
  </card>
</template>
<script>
export default {
  name: "info",
  props: {
    items: null
  }
};
</script>
<style scoped lang="scss">
.just_wrapping {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  // padding: 0.75rem;
  @media (min-width: 320px) and (max-width: 400px) {
    gap: 0;
  }
  .part_a {
  }
  .part_b {
  }
}
::v-deep .table td,
.table th {
  border: none;
}
</style>
