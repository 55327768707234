import Resource from "../api/resource";
import request from "../utils/request";

class Questions extends Resource {
  constructor() {
    super("questions");
  }

  update(resource, id) {
    return request({
      url: `/questions/update/${id}`,
      method: "put",
      data: resource
    });
  }
  add(resource) {
    return request({
      url: `/questions/add_question`,
      method: "post",
      data: resource
    });
  }
  form() {
    return request({
      url: `/forms`,
      method: "post"
    });
  }

  show_question(id) {
    return request({
      url: `/questions/show/${id}`,
      method: "get"
    });
  }

  Import(resource) {
    return request({
      url: `/questions/import`,
      method: "post",
      "Content-Type": "multipart/form-data",
      data: resource
    });
  }
  Export(resource) {
    return request({
      url: `/questions/export`,
      method: "post",
      responseType: "blob",
      data: resource
    });
  }

  // bulk process
  Import_bulk(resource) {
    return request({
      url: `/questions-language/import`,
      method: "post",
      "Content-Type": "multipart/form-data",
      data: resource
    });
  }
  Export_bulk() {
    return request({
      url: `questions-language/export`,
      method: "post",
      responseType: "blob"
    });
  }
}

export { Questions as default };
