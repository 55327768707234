// import {GetQuestions, SaveQuestions,deleteQuestion} from '../../api/questionnaire'; // getInfo
import Groups from "../../api/groups"; // getInfo
// import {Auth, deleteToken, removeAuth, removeToken, resetPermission, setLogged, setToken} from '../../utils/auth';
const FileDownload = require("js-file-download");
const groups = new Groups();

const groupsModule = {
  namespaced: true,
  state: {
    usersLoad: false,
    contacts: [],
    topics: [],
    totalTopics: null,
    userGroups: [],
    allGroups: [],
    groupMembers: [],
    instUsers:[]
  },
  getters: {
    contacts: state => {
      return state.contacts;
    },
    userGroups: state => {
      return state.userGroups;
    },
    allGroups: state => {
      return state.allGroups;
    },
    totalTopics: state => {
      return state.totalTopics;
    },
    usersLoad: state => {
      return state.usersLoad;
    },
    groupMembers: state => {
      return state.groupMembers;
    },
    getInstUsers: state => {
      return state.instUsers;
    }
  },

  mutations: {
    SET_CONTACTS: (state, contacts) => {
      state.contacts = contacts;
    },
    SET_GROUP_MEMBERS: (state, groupMembers) => {
      state.groupMembers = groupMembers;
    },

    SET_TOTAL_TOPICS: (state, totalTopics) => {
      state.totalTopics = totalTopics;
    },
    SET_USER_GROUPS: (state, userGroups) => {
      state.userGroups = userGroups;
    },

    SET_GROUPS: (state, allGroups) => {
      state.allGroups = allGroups;
    },
    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    },
    SET_INSTITUTIONS_USERS: (state, inst) => {
      state.instUsers = inst;
    }
  },
  actions: {
    GetAllContacts({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        groups
          .list()
          .then(response => {
            commit("SET_GROUPS", response.groups);
            // commit('SET_TOTAL_TOPICS', response.count);

            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    GetUserGroups({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        groups
          .user_groups()
          .then(response => {
            commit("SET_USER_GROUPS", response.groups);
            // commit('SET_TOTAL_TOPICS', response.count);

            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    UpdateGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        groups
          .update(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    AddGroup({ commit }, payload) {
      // console.log(payload.query)
      return new Promise((resolve, reject) => {
        groups
          .add(payload.query)

          // form.add(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        groups
          .delete_group(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    //// export every group
    export_every_group({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        groups
          .export_group(payload.id)
          .then(response => {
            resolve(response);
            FileDownload(response, `${payload.group_name}.xlsx`);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },

    getGroupMembers({ commit }) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        groups
          .group_members()
          .then(response => {
            commit("SET_GROUP_MEMBERS", response.groups);
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // getGroupMembers({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     commit("SET_USERS_LOAD", true);
    //     groups
    //       .group_members(payload.query)
    //       .then(response => {
    //         commit("SET_GROUP_MEMBERS", response.groups);

    //         commit("SET_USERS_LOAD", false);

    //         resolve();
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },
    addMemberToGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        groups
          .group_members()
          .then(response => {
            console.log(response);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addNewGroupMembers({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        groups
          .add_group_memberss(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // added members to group
    addMembersToGroup({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        groups
          .add_members_to_group(payload.query, payload.id)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    UpdateNewGroupMembers({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        groups
          .edit_group_memberss(payload.query, payload.id)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    editeGroupName({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        groups
          .edit_group(payload.query, payload.id)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    deleteMemberFromGroup({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        groups
          .delete_group_member(payload.group_id, payload.member_id)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", true);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", true);
          });
      });
    },
    institutionUsersGroup({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        groups
          .institutions_users()
          .then(response => {
            commit("SET_INSTITUTIONS_USERS", response.institution_users);
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};

export default groupsModule;
