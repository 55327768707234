import Organization from "../../api/organization"; // getInfo
const FileDownload = require("js-file-download");
const organization = new Organization();
const organizationModule = {
  namespaced: true,
  state: {
    organizations: [],
    singleOrganization: null,
    usersLoad: false,
    total: null,
    categories:[],
    setOrgs:[]
  },
  getters: {
    singleOrganization: state => {
      return state.singleOrganization;
    },
    organizations: state => {
      return state.organizations;
    },
    getCategories: state => {
      return state.categories;
    },
    orgs: state => {
      return state.organizations;
    },
    forms: state => {
      return state.forms;
    },
    total: state => {
      return state.total;
    },
    usersLoad: state => {
      return state.usersLoad;
    },
    getOrgsData: state => {
      return state.setOrgs;
    },

  },

  mutations: {
    SET_ORGANIZATIONS: (state, organizations) => {
      state.organizations = organizations;
    },
    SET_ORGS_DATA: (state, org) => {
      state.setOrgs = org;
    },
    SET_ORGANIZATION: (state, singleOrganization) => {
      state.singleOrganization = singleOrganization;
    },
    SET_CATEGORIES: (state, cat) => {
      state.categories = cat;
    },

    SET_FORMS: (state, forms) => {
      state.forms = forms;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_GROUP_ID: (state, group_id) => {
      if (group_id == 1) {
        state.user.age_group_id = "6-12 years old";
      }
      if (group_id == 2) {
        state.user.age_group_id = "13 -21 years old";
      }

      if (group_id == 1) {
        state.user.age_group_id = "22 or older";
      }
    },
    SET_GROUP_NAME: (state, group_name) => {
      if (group_name == "6-12 years old") {
        state.user.age_group_id = 1;
      }
      if (group_name == "13 -21 years old") {
        state.user.age_group_id = 2;
      }

      if (group_name == "22 or older") {
        state.user.age_group_id = 3;
      }
    },
    SET_LOAD_USER: (state, load) => {
      state.userLoad = load;
    },
    SET_TOTAL_ORG: (state, total) => {
      state.total = total;
    },
    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    },
    SET_COURSES: (state, courses) => {
      state.courses = courses;
    },
    SET_TASKS: (state, tasks) => {
      state.tasks = tasks;
    }
  },
  actions: {
    Import({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // commit('SET_USERS_LOAD', true);
        organization
          .Import(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    Export({ commit }, payload) {
      return new Promise((resolve, reject) => {
        organization
          .Export(payload.query)
          .then(response => {
            FileDownload(response, "institutions.xlsx");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    orgs({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        organization
          .getOrgs()
          .then(response => {
            commit("SET_ORGANIZATIONS", response.organizations);
            commit("SET_ORGS_DATA", response.organizations);
            console.log("all", response.organizations);
            commit("SET_TOTAL_ORG", response.count);

            commit("SET_USERS_LOAD", false);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    organizations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        organization
          .list(payload.query)

          .then(response => {
            commit("SET_ORGANIZATIONS", response.organizations);
            commit("SET_CATEGORIES", response.categories);
            // console.log(response.categories);
            commit("SET_TOTAL_ORG", response.count);
            commit("SET_USERS_LOAD", false);

            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    organization({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        organization
          .get(id)
          .then(response => {
            commit("SET_ORGANIZATION", response.organization);
            commit("SET_USERS_LOAD", false);

            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    saveNewOrganization({ commit }, payload) {
      return new Promise((resolve, reject) => {
        organization
          .savenew(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    saveOrganization({ commit }, payload) {
      return new Promise((resolve, reject) => {
        organization
          .save(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // addOrganizationPayment({commit}, payload) {
    //   return new Promise((resolve, reject) => {
    //     organization.addOrganizationPayment(payload.query, payload.id)
    //       .then(response => {
    //         resolve();
    //       }).catch(error => {
    //       reject(error);
    //     });
    //   });
    // },

    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        organization
          .delete(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // add users from institution to another institution
    changeOrganization({ commit }, payload) {
      commit("SET_USERS_LOAD", true);
      return new Promise((resolve, reject) => {
        organization
          .change_organization(payload)
          .then(response => {
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            commit("SET_USERS_LOAD", false);
            reject(error);
          });
      });
    },
  },

  // change_organization
};

export default organizationModule;
