<template>
  <div class="header-sidebar">
    <div class="logo-section">
      <img
        src="../../../assets/images/logo/PLC-Logo@2x.png"
        alt="Personal Learning Coach"
        class="logo"
      />
      <button class="close-btn" v-if="hideXIcon" @click="hideAndShowSidebar">
        ✖
      </button>
    </div>
    <div
      class="sidebar-section"
      v-for="(item, index) in sidebarItems"
      :key="index"
    >
      <div class="icon-wrapper" v-if="hideShowSidebar == false">
        <i :class="item.icon"></i>
      </div>
      <div v-if="hideShowSidebar == false">
        <h3>{{ item.title }}</h3>
        <p>{{ item.description }}</p>
      </div>
    </div>
    <div
      class="absolute_question_mark"
      v-if="!hideXIcon"
      :class="{ make_absolute: hideShowSidebar == true }"
    >
      <div
        @click="hideAndShowSidebar"
        role="button"
        class="mark_tooltip text-center"
        v-tooltip.top-center="`${'Useful tips and support'}`"
      >
        <img src="@/assets/new-theme/icons/help-circle@2x.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hideShowSidebar: false,
      hideXIcon: true,
      sidebarItems: [
        {
          icon: "fa fa-calendar", // FontAwesome v4.7.0 class for calendar icon
          title: "FREE 14-day trial",
          description:
            "FREE 14-day trial period for all Individual Plans! You won’t be charged until your trial period has ended."
        },
        {
          icon: "fa fa-refresh", // FontAwesome v4.7.0 class for auto-renew
          title: "Auto-renews",
          description:
            "Don’t worry about repeating the purchase over and over again. We will automatically renew when the period ends."
        },
        {
          icon: "fa fa-comment", // FontAwesome v4.7.0 class for help
          title: "Our people make the difference",
          description:
            "We’re an extension of your customer service team. Chat to our friendly team 24/7 when you need help."
        }
      ]
    };
  },
  methods: {
    hideAndShowSidebar() {
      this.hideShowSidebar = !this.hideShowSidebar;
      this.hideXIcon = !this.hideXIcon;
    }
  }
};
</script>

<style lang="scss" scoped>
/* Sidebar Container */
.header-sidebar {
  padding: 30px;
  background-color: #fcfcfd;
  border-radius: 15px;
  /* height: 100vh; */
  width: 637px;
  flex: 1;
}

/* Logo Section */
.logo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.logo {
  width: 110px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Individual Sidebar Sections */
.sidebar-section {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 15px;
  background-color: #fff;
}

/* Icon Wrapper Styling */
.icon-wrapper {
  width: 50px;
  height: 50px;
  background-color: #f5e8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
}

i {
  color: #7f56d9;
  font-size: 20px;
}

/* Text Styling */
.sidebar-section h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 15px 0 15px;
  line-height: 1.6;
  color: #333;
}

.sidebar-section p {
  font-size: 14px;
  color: #666;
  margin: 0;
}
.absolute_question_mark {
  &.make_absolute {
    position: absolute;
    bottom: 0;
  }
}
.mark_tooltip {
  border: 1px solid #d0d5dd;
  width: fit-content;
  padding: 7px 1px;
  border-radius: 50%;
  img {
    width: 60%;
  }
}
</style>
