import Resource from "./resource";
import request from "../utils/request";

class Strategies extends Resource {
  constructor() {
    super("strategies");
  }

  update(resource, id) {
    return request({
      url: `/strategies/update/${id}`,
      method: "put",
      data: resource
    });
  }

  show_strategy(id) {
    return request({
      url: `/strategies/show/${id}`,
      method: "get"
    });
  }

  add(resource) {
    return request({
      url: `/strategies/add_strategy`,
      method: "post",
      data: resource
    });
  }
  addCustom(resource, id) {
    return request({
      url: `/strategies/add_custom_strategy/${id}`,
      method: "put",
      data: resource
    });
  }

  strategies(resource) {
    return request({
      url: `/strategies`,
      method: "post",
      params: resource
    });
  }

  Import(resource) {
    return request({
      url: `/strategies/import`,
      method: "post",
      "Content-Type": "multipart/form-data",
      data: resource
    });
  }
  Export(resource) {
    return request({
      url: `/strategies/export`,
      method: "post",
      responseType: "blob",
      data: resource
    });
  }
  // bulk process
  Import_bulk(resource) {
    return request({
      url: `/strategies-language/import`,
      method: "post",
      "Content-Type": "multipart/form-data",
      data: resource
    });
  }
  Export_bulk() {
    return request({
      url: `strategies-language/export`,
      method: "post",
      responseType: "blob"
    });
  }
  // at work and career
  addCustomAtWorkStrategy(resource, id) {
    return request({
      url: `/strategies/add/work/custom/strategy/${id}`,
      method: "put",
      data: resource
    });
  }
  addCustomCareerStrategy(resource, id) {
    return request({
      url: `/strategies/add/career/custom/strategy/${id}`,
      method: "put",
      data: resource
    });
  }
}

export { Strategies as default };
