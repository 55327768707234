import Resource from "./resource";
import request from "../utils/request";

class Attributes extends Resource {
  constructor() {
    super("attributes");
  }

  // get attributes list
  get_attributes(resource) {
    return request({
      url: `/attributes`,
      method: "post",
      params: resource
    });
  }

  add_attribute(resource) {
    return request({
      url: `/attributes/add/attribute`,
      method: "post",
      data: resource
    });
  }

  edit_attribute(resource, id) {
    return request({
      url: `/attributes/edit/attribute/${id}`,
      method: "put",
      data: resource
    });
  }

  delete_attribute(id) {
    return request({
      url: `/attributes/delete/attribute/${id}`,
      method: "delete"
    });
  }

  Import(resource) {
    return request({
      url: `/attributes/import`,
      method: "post",
      "Content-Type": "multipart/form-data",
      data: resource
    });
  }
  Export(resource) {
    return request({
      url: `/attributes/export`,
      method: "post",
      responseType: "blob",
      data: resource
    });
  }

  // bulk process
  Import_bulk(resource) {
    return request({
      url: `/attributes-language/import`,
      method: "post",
      "Content-Type": "multipart/form-data",
      data: resource
    });
  }
  Export_bulk() {
    return request({
      url: `attributes-language/export`,
      method: "post",
      responseType: "blob"
    });
  }
  //// Start Team Statements
  get_team_statements(resource) {
    return request({
      url: `/statements`,
      method: "post",
      params: resource
    });
  }
  add_team_statement(resource) {
    return request({
      url: `/statements/add/statement`,
      method: "post",
      data: resource
    });
  }
  edit_team_statement(resource, id) {
    return request({
      url: `/statements/edit/statement/${id}`,
      method: "put",
      data: resource
    });
  }
  delete_team_statement(id) {
    return request({
      url: `/statements/delete/statement/${id}`,
      method: "delete"
    });
  }
  get_user_statements(resource) {
    return request({
      url: `/users/statements`,
      method: "post",
      params: resource
    });
  }
   //// End Team Statements

   // Start  Statements Questions
  get_statements_questions(resource) {
    return request({
      url: `/statements/questions`,
      method: "post",
      params: resource
    });
  }
  add_statement_questions(resource) {
    return request({
      url: `/statements/questions/add/statement`,
      method: "post",
      data: resource
    });
  }
  edit_statement_questions(resource, id) {
    return request({
      url: `/statements/questions/edit/${id}`,
      method: "put",
      data: resource
    });
  }
  delete_statement_questions(id) {
    return request({
      url: `/statements/questions/delete/${id}`,
      method: "delete"
    });
  }
   // End  Statements Questions

   // Start Task Categories
   get_task_categories(resource) {
    return request({
      url: `/categories`,
      method: "post",
      params: resource
    });
  }
  add_new_category(payload) {
    if (payload.id != null) {
      return request({
        url: `/categories/save/${payload.id}`,
        method: "put",
        data: payload
      });
    } else {
      return request({
        url: `/categories/save`,
        method: "put",
        data: payload
      });
    }
  }
  delete_category(id) {
    return request({
      url: `/categories/delete/${id}`,
      method: "delete"
    });
  }
  show_category_details(resource,id) {
    return request({
      url: `/categories/tasks/${id}`,
      method: "post",
      params: resource
    });
  }
  add_new_task(payload) {
    if (payload.id != null) {
      return request({
        url: `/categories/tasks/save/${payload.id}`,
        method: "put",
        data: payload
      });
    } else {
      return request({
        url: `/categories/tasks/save`,
        method: "put",
        data: payload
      });
    }
  }
  delete_task(id) {
    return request({
      url: `/categories/tasks/delete/${id}`,
      method: "delete"
    });
  }
  // End Task Categories
   // Introduce Yourself
   update_introducing_statements(resource, id) {
    return request({
      url: `users/update/statements/${id}`,
      method: "put",
      data: resource
    });
  }
  update_career_match_statements(resource, id) {
    return request({
      url: `users/update/career/statements/${id}`,
      method: "put",
      data: resource
    });
  }
}

export { Attributes as default };
