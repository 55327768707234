<template>
  <footer class="footer-buttons">
    <div class="step-buttons">
      <!-- Back Button -->
      <el-button
        type="info"
        plain
        @click="prevStep"
        v-if="!(currentStep <= 1)"
        class="custom-button back-btn"
      >
        <i class="el-icon-back"></i>
      </el-button>

      <!-- Continue Button -->
      <el-button
        type="primary"
        icon-right
        @click="nextStep"
        v-if="!(currentStep >= totalSteps)"
        class="custom-button continue-btn"
      >
        Continue
      </el-button>

      <el-button
        type="primary"
        @click="emitSignUp"
        v-if="currentStep >= totalSteps"
        :disabled="disableSubmit"
        class="custom-button continue-btn"
      >
        <!-- {{ publicLocalization("Create Account") }} -->
        Get started!
      </el-button>
      <!-- Forward Button -->
      <el-button
        type="info"
        plain
        @click="nextStep"
        v-if="!(currentStep >= totalSteps)"
        class="custom-button back-btn"
      >
        <i class="el-icon-right"></i>
      </el-button>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    },
    disableSubmit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    prevStep() {
      if (this.currentStep > 1) {
        this.$emit("update:currentStep", this.currentStep - 1);
      }
    },
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.$emit("update:currentStep", this.currentStep + 1);
      }
    },
    emitSignUp() {
      this.$emit("sign-up");
    }
  }
};
</script>

<style scoped lang="scss">
.footer-buttons {
  position: fixed; /* Keep it fixed at the bottom */
  bottom: -4rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  box-shadow: 0 -5px 2px 0 rgba(0, 0, 0, 0.025);
  z-index: 10;
}
.step-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* Center alignment for the container */
.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Step button container styles */
.step-buttons {
  display: flex;
  gap: 16px; /* Space between buttons */
}

/* Back Button Styling */
.back-btn {
  background-color: #f9fafb;
  color: #000;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-weight: 500;
  padding: 8px 16px;
  box-shadow: none;
  transition: all 0.3s ease;
}
.back-btn:hover {
  background-color: #5e3cba;
  color: #fff;
}

/* Continue Button Styling */
.continue-btn {
  background-color: #e5e7eb;
  color: #667085;
  border: 0;
  border-radius: 8px;
  font-weight: 500;
  padding: 8px 4rem;
  box-shadow: none;
  transition: all 0.3s ease;
}
.continue-btn:hover {
  background-color: #5e3cba;
  color: #fff;
}
</style>
