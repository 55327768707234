import Resource from '../api/resource';
import request from "../utils/request";

class Roles extends Resource {
  constructor() {
    super('role');
  }

  list() {
    return request({
      url: `/roles`,
      method: 'get',
    })
  }

  get(id) {
    return request({
      url: `/roles/${id}`,
      method: 'get',
    })
  }

  add(resource) {
    return request({
      url: `/roles`,
      method: 'put',
      data: resource,
    })
  }

  update(resource, id) {
    return request({
      url: `/roles/${id}`,
      method: 'put',
      data: resource,
    })
  }

  delete(id) {
    return request({
      url: `/roles/${id}`,
      method: 'delete',
    })
  }

  userRoleAndPermissions(user_id) {
    return request({
      url: `/user/roles/${user_id}`,
      method: 'get',
    })
  }

  updateUserRoleAndPermissions(resource, user_id) {
    return request({
      url: `/user/roles/${user_id}`,
      method: 'put',
      data: resource,
    })
  }


}

export {Roles as default};
