import questionnaire from "../../api/questionnaire";
import Question from "../../api/questionnaire"; // getInfo
const FileDownload = require("js-file-download");

const question = new Question();

const questionnaireModule = {
  namespaced: true,
  state: {
    questions: [],
    forms: [],
    total: null,
    group_id: null,
    auth: null,
    user: {},
    oneQuestion: [],
    userLoad: false,
    token: null,
    users: [],
    tasks: [],
    courses: [],
    languages: [],
    usersLoad: false,
    totalUsers: 0,
    sequence: null,
    technical_reasoning: null,
    confluence: null,
    precision: null
  },
  getters: {
    questions: state => {
      return state.questions;
    },
    oneQuestion: state => {
      return state.oneQuestion;
    },

    total: state => {
      return state.total;
    },
    forms: state => {
      return state.forms;
    },
    languages: state => {
      return state.languages;
    },

    usersLoad: state => {
      return state.usersLoad;
    }
  },

  mutations: {
    SET_questions: (state, questions) => {
      state.questions = questions;
    },
    SET_ONE_QU: (state, oneQuestion) => {
      state.oneQuestion = oneQuestion;
    },
    SET_LANG: (state, languages) => {
      state.languages = languages;
    },
    SET_FORMS: (state, forms) => {
      state.forms = forms;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_TOTAL_QUESTIONS: (state, total) => {
      state.total = total;
    },
    SET_GROUP_ID: (state, group_id) => {
      if (group_id == 1) {
        state.user.age_group_id = "6-12 years old";
      }
      if (group_id == 2) {
        state.user.age_group_id = "13 -21 years old";
      }

      if (group_id == 1) {
        state.user.age_group_id = "22 or older";
      }
    },
    SET_GROUP_NAME: (state, group_name) => {
      if (group_name == "6-12 years old") {
        state.user.age_group_id = 1;
      }
      if (group_name == "13 -21 years old") {
        state.user.age_group_id = 2;
      }

      if (group_name == "22 or older") {
        state.user.age_group_id = 3;
      }
    },
    SET_LOAD_USER: (state, load) => {
      state.userLoad = load;
    },
    SET_TOTAL_USERS: (state, total) => {
      state.totalUsers = total;
    },
    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    },
    SET_COURSES: (state, courses) => {
      state.courses = courses;
    },
    SET_TASKS: (state, tasks) => {
      state.tasks = tasks;
    }
  },
  actions: {
    questions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        question
          .list(payload.query)
          .then(response => {
            commit("SET_TOTAL_QUESTIONS", response.count);
            commit("SET_questions", response.questions);
            commit("SET_LANG", response.languages);
            commit("SET_USERS_LOAD", false);

            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    show_question({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        question
          .show_question(payload.id)
          .then(response => {
            commit("SET_ONE_QU", response.question);
            commit("SET_LANG", response.languages);
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    saveQuestionnaire({ commit }, payload) {
      return new Promise((resolve, reject) => {
        question
          .store(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    UpdateQuestion({ commit }, payload) {
      return new Promise((resolve, reject) => {
        question
          .update(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    AddQuestion({ commit }, payload) {
      return new Promise((resolve, reject) => {
        question
          .add(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        question
          .delete(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetForms({ commit }) {
      return new Promise((resolve, reject) => {
        question
          .form()
          .then(response => {
            commit("SET_FORMS", response.forms);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    Import({ commit }, payload) {
      return new Promise((resolve, reject) => {
        question
          .Import(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    Export({ commit }, payload) {
      return new Promise((resolve, reject) => {
        question
          .Export(payload.query)
          .then(response => {
            FileDownload(response, "questions.xlsx");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // Bulk process
    ImportBulk({ commit }, payload) {
      return new Promise((resolve, reject) => {
        question
          .Import_bulk(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    ExportBulk({ commit }, payload) {
      return new Promise((resolve, reject) => {
        question
          .Export_bulk()
          .then(response => {
            FileDownload(response, "Temp_questions.xlsx");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};

export default questionnaireModule;
